import routeHelper from "i18n/routeHelper";
import { HrefLangEntry } from "components/Helmet/Helmet";

function useHrefLang(routeHelperFunctionName: string): HrefLangEntry[] {
  return Object.keys(routeHelper).map((key) => {
    if (!routeHelper[key][routeHelperFunctionName]) {
      throw new Error(
        `routeHelper function not found: ${key} - ${routeHelperFunctionName}`
      );
    }

    return {
      locale: key,
      url: routeHelper[key][routeHelperFunctionName](),
      default: key === "en",
    };
  });
}

export default useHrefLang;
