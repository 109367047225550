import imageLogo from "./logo.webp";
import imageLogox2 from "./logo@2x.webp";
import imageLogox3 from "./logo@3x.webp";

import imageLogoMobile from "./logo_mobile.webp";
import imageLogoMobilex2 from "./logo_mobile@2x.webp";
import imageLogoMobilex3 from "./logo_mobile@3x.webp";

import imageIntro from "./intro.webp";
import imageIntrox2 from "./intro@2x.webp";
import imageIntrox3 from "./intro@3x.webp";

import imagePrinciples from "./principles.webp";
import imagePrinciplesx2 from "./principles@2x.webp";
import imagePrinciplesx3 from "./principles@3x.webp";
import imagePrinciplesMobile from "./principles_mobile.webp";
import imagePrinciplesMobilex2 from "./principles_mobile@2x.webp";
import imagePrinciplesMobilex3 from "./principles_mobile@3x.webp";

import animationGroupDesktop from "./group_animation.mp4";
import animationLandingDesktop from "./landing_animation.mp4";

const images = {
  imageLogo: {
    mobile: {
      webp: {
        "1x": imageLogoMobile,
        "2x": imageLogoMobilex2,
        "3x": imageLogoMobilex3
      }
    },
    desktop: {
      webp: {
        "1x": imageLogo,
        "2x": imageLogox2,
        "3x": imageLogox3
      }
    }
  },
  imageIntro: {
    all: {
      webp: {
        "1x": imageIntro,
        "2x": imageIntrox2,
        "3x": imageIntrox3
      }
    }
  },
  imagePrinciples: {
    all: {
      webp: {
        "1x": imagePrinciples,
        "2x": imagePrinciplesx2,
        "3x": imagePrinciplesx3
      }
    }
  },
  imagePrinciplesMobile: {
    all: {
      webp: {
        "1x": imagePrinciplesMobile,
        "2x": imagePrinciplesMobilex2,
        "3x": imagePrinciplesMobilex3
      }
    }
  },
  animationGroupDesktop: {
    all: {
      mp4: {
        "1x": animationGroupDesktop
      }
    }
  },
  animationLandingDesktop: {
    all: {
      mp4: {
        "1x": animationLandingDesktop
      }
    }
  }
};

export default images;
