import React from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import loadable from "@loadable/component";
import useHrefLang from "hooks/useHrefLang";
import localeRouteHelper from "i18n/routeHelper";
import FormattedMessage from "helpers/formattedMessage";
import IconRating from "./assets/rating.svg";
import assets from "./assets";

import styles from "./PeanutAtWork.module.scss";

export interface Props {}

const ButtonLink: any = loadable(
  () =>
    import(
      /* webpackChunkName: "components-ButtonLink"              */ "../../components/ButtonLink"
    )
);

const Helmet: any = loadable(
  () =>
    import(
      /* webpackChunkName: "components-Helmet"              */ "../../components/Helmet"
    )
);

function PeanutAtWork(): JSX.Element {
  const intl = useIntl();
  const routeHelper = localeRouteHelper[intl.locale];
  const hrefLang = useHrefLang("peanutAtWork");

  const outgoingUrl = "https://peanut-app.typeform.com/to/VZvj955W";
  const currentUrl = `${
    process.env.REACT_APP_BASE_URL
  }${routeHelper.peanutAtWork()}`;

  return (
    <>
      <Helmet
        canonical={currentUrl}
        title={intl.formatMessage({
          id: "pages.peanutatwork.title",
          description: "Title on Peanut at Work page",
          defaultMessage: "Peanut at Work",
        })}
        description={intl.formatMessage({
          id: "pages.peanutatwork.subtitle",
          description: "Intro section subtitle on Peanut at Work page",
          defaultMessage:
            "Peanut at Work provides employees with access to a supportive peer-to-peer community when they need it most.",
        })}
        hrefLang={hrefLang}
      />
      <div className={styles.wrapper}>
        <div className={classNames(styles.primary, styles.imagePeanutAtWork)}>
          <section className={classNames(styles.innerSection)}>
            <div
              className={styles.logoDesktop}
              data-testid="peanutatwork-image1-desktop-testid"
            >
              <img
                src={assets.imageLogo.desktop.webp["1x"]}
                srcSet={`${assets.imageLogo.desktop.webp["1x"]} 1x, ${assets.imageLogo.desktop.webp["2x"]} 2x, ${assets.imageLogo.desktop.webp["3x"]} 3x`}
                alt={intl.formatMessage({
                  id: "pages.peanutatwork.subtitle",
                  description: "Intro section subtitle on Peanut at Work page",
                  defaultMessage:
                    "Peanut at Work provides employees with access to a supportive peer-to-peer community when they need it most.",
                })}
              />
            </div>
            <div
              className={styles.logoMobile}
              data-testid="peanutatwork-image1-mobile-testid"
            >
              <img
                src={assets.imageLogo.mobile.webp["1x"]}
                srcSet={`${assets.imageLogo.mobile.webp["1x"]} 1x, ${assets.imageLogo.mobile.webp["2x"]} 2x, ${assets.imageLogo.mobile.webp["3x"]} 3x`}
                alt={intl.formatMessage({
                  id: "pages.peanutatwork.subtitle",
                  description: "Intro section subtitle on Peanut at Work page",
                  defaultMessage:
                    "Peanut at Work provides employees with access to a supportive peer-to-peer community when they need it most.",
                })}
              />{" "}
            </div>
            <p data-testid="peanutatwork-subtitle-testid">
              <FormattedMessage
                id="pages.peanutatwork.subtitle"
                description="Intro section subtitle on Peanut at Work page"
                defaultMessage="Peanut at Work provides employees with access to a supportive <b>peer-to-peer community</b> when they need it most."
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </p>
            <ButtonLink to={outgoingUrl} className={styles.primaryButton}>
              <FormattedMessage
                id="pages.peanutatwork.learnmore.button"
                description="Learn more button on Peanut at Work page"
                defaultMessage="Learn more"
              />
            </ButtonLink>
            <footer>
              <img src={IconRating} alt="rating" className={styles.rating} />
              <FormattedMessage
                id="pages.peanutatwork.trustedby"
                description="Trusted by text on Peanut at Work page"
                defaultMessage="Trusted by 5M+ women monthly"
              />
            </footer>
          </section>
        </div>

        <section
          className={styles.introSection}
          data-testid="peanutatwork-intro-section-testid"
        >
          <div className={styles.introTextContainer}>
            <ul className={styles.introTitle}>
              <li>
                <p>
                  <FormattedMessage
                    id="pages.peanutatwork.bullet1"
                    description="Bullet point 1 on Peanut at Work page"
                    defaultMessage="<b>90%</b> of women battling infertility experience <b>depression</b>"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </p>
              </li>
              <li>
                <p>
                  <FormattedMessage
                    id="pages.peanutatwork.bullet2"
                    description="Bullet point 2 on Peanut at Work page"
                    defaultMessage="<b>1 in 7</b> women develop <b>postpartum depression</b>"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </p>
              </li>
              <li>
                <p>
                  <FormattedMessage
                    id="pages.peanutatwork.bullet3"
                    description="Bullet point 3 on Peanut at Work page"
                    defaultMessage="<b>90%</b> of mothers feel <b>lonely</b>"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </p>
              </li>
              <li>
                <p>
                  <FormattedMessage
                    id="pages.peanutatwork.bullet4"
                    description="Bullet point 4 on Peanut at Work page"
                    defaultMessage="<b>17%</b> of women leave employment after starting a family"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </p>
              </li>
              <li>
                <p>
                  <FormattedMessage
                    id="pages.peanutatwork.bullet5"
                    description="Bullet point 5 on Peanut at Work page"
                    defaultMessage="<b>1 in 3</b> women change jobs due to <b>concern for family</b>"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </p>
              </li>

              <li>
                <p>
                  <FormattedMessage
                    id="pages.peanutatwork.bullet6"
                    description="Bullet point 6 on Peanut at Work page"
                    defaultMessage="<b>42%</b> of menopausal women <b>consider leaving jobs</b> due to symptoms"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </p>
              </li>
            </ul>
            <ButtonLink to={outgoingUrl} className={styles.primaryButton}>
              <FormattedMessage
                id="pages.peanutatwork.getemployeesupport.button"
                description="Get employee support button on Peanut at Work page"
                defaultMessage="Get employee support"
              />
            </ButtonLink>
          </div>
          <div
            className={styles.introImage}
            data-testid="peanutatwork-image2-testid"
          >
            <img
              src={assets.imageIntro.all.webp["1x"]}
              srcSet={`${assets.imageIntro.all.webp["1x"]} 1x, ${assets.imageIntro.all.webp["2x"]} 2x, ${assets.imageIntro.all.webp["3x"]} 3x`}
              alt={intl.formatMessage({
                id: "pages.peanutatwork.getemployeesupport.button",
                description:
                  "Get employee support button on Peanut at Work page",
                defaultMessage: "Get employee support",
              })}
            />
          </div>
        </section>

        <section
          className={styles.principlesSection}
          data-testid="peanutatwork-principles-section-testid"
        >
          <div
            className={styles.principlesImage}
            data-testid="peanutatwork-image3-desktop-testid"
          >
            <img
              src={assets.imagePrinciples.all.webp["1x"]}
              srcSet={`${assets.imagePrinciples.all.webp["1x"]} 1x, ${assets.imagePrinciples.all.webp["2x"]} 2x, ${assets.imagePrinciples.all.webp["3x"]} 3x`}
              alt={intl.formatMessage({
                id: "pages.peanutatwork.principles_title",
                description: "Intro principles header on Peanut at Work page",
                defaultMessage:
                  "Make meaningful progress towards gender equality",
              })}
            />
          </div>

          <div
            className={styles.principlesImageMobile}
            data-testid="peanutatwork-image3-mobile-testid"
          >
            <img
              src={assets.imagePrinciplesMobile.all.webp["1x"]}
              srcSet={`${assets.imagePrinciples.all.webp["1x"]} 1x, ${assets.imagePrinciples.all.webp["2x"]} 2x, ${assets.imagePrinciples.all.webp["3x"]} 3x`}
              alt={intl.formatMessage({
                id: "pages.peanutatwork.principles_title",
                description: "Intro principles header on Peanut at Work page",
                defaultMessage:
                  "Make meaningful progress towards gender equality",
              })}
            />
          </div>
          <div className={styles.principlesTextContainer}>
            <h1
              className={styles.principlesTitle}
              data-testid="peanutatwork-principles-heading-testid"
            >
              <FormattedMessage
                id="pages.peanutatwork.principles_title"
                description="Intro principles header on Peanut at Work page"
                defaultMessage="Make meaningful{br}progress towards{br}gender equality"
                values={{
                  br: <br />,
                }}
              />
            </h1>
            <ul>
              <li>
                <p>
                  <FormattedMessage
                    id="pages.peanutatwork.bullet7"
                    description="Bullet point 7 on Peanut at Work page"
                    defaultMessage="Providing support for underserved health journeys leads to
                increased <b>motivation, productivity</b> and
                <b>retention rates.</b>"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </p>
              </li>
              <li>
                <p>
                  <FormattedMessage
                    id="pages.peanutatwork.bullet8"
                    description="Bullet point 8 on Peanut at Work page"
                    defaultMessage="Offering more flexible, innovative, and
                <b>socially responsible company benefits</b> is a
                meaningful differentiator."
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </p>
              </li>
              <li>
                <p>
                  <FormattedMessage
                    id="pages.peanutatwork.bullet9"
                    description="Bullet point 9 on Peanut at Work page"
                    defaultMessage="Helping LGBTQ+ women who require support to start a family
                promotes <b>diversity and inclusion.</b>"
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </p>
              </li>
              <li>
                <p>
                  <FormattedMessage
                    id="pages.peanutatwork.bullet10"
                    description="Bullet point 10 on Peanut at Work page"
                    defaultMessage="Companies with family-friendly policies showcase a
                <b>better corporate brand</b> to the outside world."
                    values={{
                      b: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </p>
              </li>
            </ul>
          </div>
        </section>

        <div
          className={styles.secondary}
          data-testid="peanutatwork-secondary-section-testid"
        >
          <section
            className={classNames(styles.innerSection, styles.secondary)}
          >
            <h2>
              <FormattedMessage
                id="pages.peanutatwork.howitworks.title"
                description="How it works title on Peanut at Work page"
                defaultMessage="How it works"
              />
            </h2>
            <h3>
              <FormattedMessage
                id="pages.peanutatwork.howitworks.description"
                description="How it works description on Peanut at Work page"
                defaultMessage="Peanut at Work creates a safe space for your workforce to connect, ask questions and find support across key life stages."
              />
            </h3>
            <p data-testid="peanutatwork-whatsincluded-testid">
              <FormattedMessage
                id="pages.peanutatwork.whatsincluded"
                description="What's included on Peanut at Work page"
                defaultMessage="What's included?"
              />
            </p>

            <ul>
              <li className={styles.imageProfile}>
                <video playsInline autoPlay loop muted disablePictureInPicture>
                  <source
                    src={assets.animationGroupDesktop.all.mp4["1x"]}
                    type="video/mp4"
                  />
                </video>
              </li>

              <div className={styles.rightPane}>
                <li className={styles.connect}>
                  <p>
                    <FormattedMessage
                      id="pages.peanutatwork.connect"
                      description="Connect bullet point on Peanut at Work page"
                      defaultMessage="Invite your team to join a dedicated community for your workforce."
                    />
                  </p>
                </li>
                <li className={styles.chat}>
                  <p>
                    <FormattedMessage
                      id="pages.peanutatwork.chat"
                      description="Chat bullet point on Peanut at Work page"
                      defaultMessage="Employees can connect, ask questions and get advice across each life stage."
                    />
                  </p>
                </li>
                <li className={styles.announce}>
                  <p>
                    <FormattedMessage
                      id="pages.peanutatwork.announce"
                      description="Announce bullet point on Peanut at Work page"
                      defaultMessage="Employers can share important company announcements and engage in real-time."
                    />
                  </p>
                </li>
              </div>

              <li className={styles.imageCards}>
                <video playsInline autoPlay loop muted disablePictureInPicture>
                  <source
                    src={assets.animationLandingDesktop.all.mp4["1x"]}
                    type="video/mp4"
                  />
                </video>
              </li>
              <div className={styles.leftPane}>
                <li className={styles.gift}>
                  <p>
                    <FormattedMessage
                      id="pages.peanutatwork.gift"
                      description="Gift bullet point on Peanut at Work page"
                      defaultMessage="Employees get a premium experience with a free Peanut Plus subscription."
                    />
                  </p>
                </li>
                <li className={styles.wave}>
                  <p>
                    <FormattedMessage
                      id="pages.peanutatwork.wave"
                      description="Wave bullet point on Peanut at Work page"
                      defaultMessage="Employees can find new connections fast with unlimited access to see who’s waved."
                    />
                  </p>
                </li>
                <li className={styles.boost}>
                  <p>
                    <FormattedMessage
                      id="pages.peanutatwork.boost"
                      description="Boost bullet point on Peanut at Work page"
                      defaultMessage="Employee profiles are boosted, increasing the chances of finding connection."
                    />
                  </p>
                </li>
                <li>
                  <p>
                    <FormattedMessage
                      id="pages.peanutatwork.muchmore"
                      description="Much more bullet point on Peanut at Work page"
                      defaultMessage="and much more!"
                    />
                  </p>
                </li>
              </div>
            </ul>
          </section>
        </div>

        <div
          className={styles.tertiary}
          data-testid="peanutatwork-tertiary-section-testid"
        >
          <section className={classNames(styles.innerSection)}>
            <blockquote>
              <FormattedMessage
                id="pages.peanutatwork.testimonial"
                description="Testimonial on peanut at work page"
                defaultMessage="“Peanut has been a game-changer for me and my productivity—it’s a part of my self-care.{br}{br}Having a safe space to talk openly about shared struggles helps me know I’m not alone.”"
                values={{
                  br: <br />,
                }}
              />
            </blockquote>

            <p>Lydia</p>

            <ButtonLink to={outgoingUrl} className={styles.tertiaryButton}>
              <FormattedMessage
                id="pages.peanutatwork.scheduledemo.button"
                description="Schedule demo button on Peanut at Work page"
                defaultMessage="Schedule demo"
              />
            </ButtonLink>
          </section>
        </div>
      </div>
    </>
  );
}

export default PeanutAtWork;